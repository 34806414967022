<template>
  <div id="mentions_legales_extranet">
    <MentionsLegalesExtranetDe v-if="country === 'de'" />
    <MentionsLegalesExtranetFr v-else />
  </div>
</template>

<script>
import MentionsLegalesExtranetFr from "@/components/legal/extranet/MentionsLegalesExtranetFr.vue";
import MentionsLegalesExtranetDe from "@/components/legal/extranet/MentionsLegalesExtranetDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de Extranet dans la bonne langue.
 */
export default {
  name: "MentionsLegalesExtranet",
  components: {
    MentionsLegalesExtranetFr,
    MentionsLegalesExtranetDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
